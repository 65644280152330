import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import About from "../components/About"
import Service from "../components/Service"
import Project from "../components/Project"
import Member from "../components/Member"
import BlogHomeList from "../components/BlogHomeList"
import Contact from "../components/Contact"

const Home = () => (
  <Layout>
    <Seo title="HOME" />
    <Hero />
    <About />
    <Service />
    <Project />
    <Member />
    <BlogHomeList />
    <Contact />
  </Layout>
)

export default Home

