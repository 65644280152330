import React from 'react'
import { GlobalStyle } from "./styles/GlobalStyles"
import Header from './Header'
import Footer from './Footer'
import "normalize.css"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      { children }
      <Footer />
    </>
  )
}

export default Layout
