import React from 'react'
import styled from "styled-components"
import ProjectOne from "../assets/images/innos.jpg"
import ProjectTwo from "../assets/images/gogokoza.png"
import { useInView } from 'react-intersection-observer'
import { InView } from './styles/InView'

const Project = () => {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  return (
    <>
      <InView />
      <ProjectWrapper id="project">
        <ProjectH1>PROJECT</ProjectH1>
        <OurProject ref={ref}>
          <ATag href="https://innos.studio/" target="_blank" rel="noopener noreferrer">
            <AboutProject>
              <ProjectTxt className={inView ? "project-txt project-txt-slide" : "project-txt"}>
                <ProjectH3>Our Service</ProjectH3>
                <ProjectH2>沖縄クリエイターズプラットフォーム</ProjectH2>
                <ProjectDetail>
                  沖縄にゆかりのあるアーティスト、イラストレーター、デザイナーなど、多彩な分野のクリエイターたちが集結し、自身の作品を発信しています。
                  さらに、クリエイター同士の交流を促進し、新たなアイデアやプロジェクトの創出をサポートしています。
                </ProjectDetail>
              </ProjectTxt>
              <ProjectImg className={inView ? "project-img project-img-slide" : "project-img"}>
                <Img src={ ProjectOne } alt="logo" />
              </ProjectImg>
            </AboutProject>
          </ATag>

          <ATag href="https://gogokoza.com/" target="_blank" rel="noopener noreferrer">
            <AboutProject>
              <ProjectTxt className={inView ? "project-txt project-txt-slide" : "project-txt"}>
                <ProjectH3>Our Service</ProjectH3>
                <ProjectH2>沖縄市コザ周辺の店舗情報</ProjectH2>
                <ProjectDetail>
                  「ゴーゴーコザ」は、沖縄市コザの活気あふれる中心市街地をご案内するサイトです。
                  パークアベニューからゴヤ十字路、ゲート通り、中の町、グランド通り、諸見百件通り周辺といったエリアの店舗情報を分かりやすく掲載しています。ディープなコザの魅力をさらに知りたい方におすすめです。
                </ProjectDetail>
              </ProjectTxt>
              <ProjectImg className={inView ? "project-img project-img-slide" : "project-img"}>
                <Img src={ ProjectTwo } alt="logo" />
              </ProjectImg>
            </AboutProject>
          </ATag>
        </OurProject>
      </ProjectWrapper>
    </>
  )
}

export default Project

const ProjectWrapper = styled.div`
  max-width: 1200px;
  padding: 120px 16px 0;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding: 120px 0px 0;
  }
`
const ProjectH1 = styled.h1`
  font-size: 42px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem auto 2.5rem;
  text-align: center;
  @media screen and (max-width: 992px) {
    font-size: 32px;
  }
  @media screen and (max-width: 768px) {
    font-size: 29px;
  }
  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`
const OurProject = styled.div`
  background: #fff;
  overflow: hidden;
`
const AboutProject = styled.div`
  margin: 2rem auto;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap-reverse;
    padding: 50px 0;
  }
`
const ATag = styled.a`
  color: #191919;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
    transition: 0.3s;
  }
`
const ProjectTxt = styled.div`
  max-width: 500px;
  margin: 16px 20px;
  @media screen and (max-width: 992px) {
    width: 50%;
    margin: 10px 0;
  }
  @media screen and (max-width: 768px) {
    width: 55%;
    margin: 5px 0;
  }
  @media screen and (max-width: 600px) {
    width: 450px;
    margin: 0 16px;
  }
`
const ProjectH3 = styled.h3`
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }
`
const ProjectH2 = styled.h2`
  font-size: 26px;
  margin: 14px 0;
  letter-spacing: 1px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`
const ProjectDetail = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 29px;
  @media screen and (max-width: 992px) {
    font-size: 15px;
  }
`
const ProjectImg = styled.div`
  max-width: 450px;
  height: 300px;
  margin: 0 20px;
  @media screen and (max-width: 992px) {
    width: 50%;
    height: 250px;
    margin: 0 16px;
  }
  @media screen and (max-width: 768px) {
    width: 45%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0 16px 2rem;
  }
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`