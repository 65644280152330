import React from 'react'
import styled from "styled-components"

const Contact = () => {
  return (
    <ContactWrapper id="contact">
      <ContactForm>
        <FormHeader>
          <FormH1>CONTACT</FormH1>
          <FormP>
            Cignalsにご興味を持っていただき誠にありがとうございます。お仕事のご相談・Co-creator制度については、こちらからご連絡ください。
          </FormP>
        </FormHeader>

        <Form 
          name="contact"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >

        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />

          <FormDiv>
            <FormLabel>
              会社名・屋号<Span>*</Span>
            </FormLabel>
            <FormInput name="company" id="company" type="text" placeholder="例) Cignals.LLC" required />
          </FormDiv>

          <FormDiv>
            <FormLabel>
              氏名<Span>*</Span>
            </FormLabel>
            <FormInput name="name" id="name" type="text" placeholder="例) 山田 太郎" required />
          </FormDiv>

          <FormDiv>
            <FormLabel>
              メールアドレス<Span>*</Span>
            </FormLabel>
            <FormInput name="email" id="email" type="email" placeholder="例) info@cignals.design" required />
          </FormDiv>

          <FormDiv>
            <FormLabel>
              電話番号<Span>*</Span>
            </FormLabel>
            <FormInput name="tel" id="tel" type="tel" pattern="[\d\-]*" placeholder="例) 07044423704" required />
          </FormDiv>

          <FormDivSelect>
            <FormLabel>
              Cignalsをどちらで知りましたか？<Span>*</Span>
            </FormLabel>
            <FormSelect name="select[]" required>
              <Option value="" hidden>-- 選択してください --</Option>
              <Option value="インターネット検索結果">インターネット検索結果</Option>
              <Option value="インターネット上の広告">インターネット上の広告</Option>
              <Option value="ソーシャルネットワーク">ソーシャルネットワーク</Option>
              <Option value="イベント">イベント</Option>
              <Option value="知人からの紹介">知人からの紹介</Option>
              <Option value="以前に利用した">以前に利用した</Option>
              <Option value="その他">その他</Option>
            </FormSelect>
          </FormDivSelect>

          <FormDiv>
            <FormLabel>
              ご要望・相談事項があればご記入ください<Span>*</Span>
            </FormLabel>
            <FormTextarea name="message" id="message" type="message" placeholder="回答入力" required></FormTextarea>
          </FormDiv>

          <FormButton type="submit">送信する</FormButton>
        </Form>
      </ContactForm>
    </ContactWrapper>
  )
}

export default Contact

const ContactWrapper = styled.div`
  width: 100%;
  padding: 120px 16px 7rem;
  background: #fff;
`
const ContactForm = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`
const Form = styled.form``
const FormHeader = styled.div``

const FormH1 = styled.h1`
  font-size: 42px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem auto 1.5rem;
  @media screen and (max-width: 992px) {
    font-size: 32px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 29px;
  }
  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`
const FormP = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  margin: 2rem 0;
  font-size: 18px;
  line-height: 35px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 30px;
  }
`
const FormDiv = styled.div`
  margin-bottom: 2rem; 
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`
const FormDivSelect = styled.div`
  margin-bottom: 2rem;
  position: relative;
  &::before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #191919;
    content: "";
    position: absolute;
    right: 25px;
    top: 53px;
    width: 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`
const FormLabel = styled.label`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`
const Span = styled.span`
  color: red;
  margin-left: 3px;
`
const FormInput = styled.input`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  outline: none;
  padding: 0 1rem;
  border: 1px solid #F3F3F4;
  &::placeholder {
    color: #A4A4A4;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`
const FormSelect = styled.select`
  font-family: 'Noto Sans JP', sans-serif;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  border: 1px solid #F3F3F4;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`
const Option = styled.option`
  /* color: #A4A4A4; */
`

const FormTextarea = styled.textarea`
  font-family: 'Noto Sans JP', sans-serif; 
  width: 100%;
  height: 230px;
  padding: 1rem;
  border-radius: 8px;
  outline: none;
  border: 1px solid #F3F3F4;
  &::placeholder {
    color: #A4A4A4;
  }
`
const FormButton = styled.button`
  font-family: 'Noto Sans JP', sans-serif;
  display: block;
  width: 240px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1.5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background: #373737;
  border: 1px solid #373737;
  border-radius: 6px;
  transition: all 0.3s;
  margin: 3rem auto;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 600px) {
    height: 50px;
    line-height: 50px;
  }
`
