import React from 'react'
import styled from "styled-components"
import Logo from "../assets/images/BgLogo.svg"
import { useInView } from 'react-intersection-observer'
import { InView } from './styles/InView'

const About = () => {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });
  
  return (
    <AboutWrapper id="about">
      <InView />
      <AboutDesc> 
        <AboutH2>
          <div className={inView ? "about-header about-header-slide" : "about-header"} ref={ref}><span>Cignals</span>は、</div>
          <div className={inView ? "about-txt about-txt-slide" : "about-txt"} ref={ref}>
            新しい経験、新しい視点、新しい挑戦をする機会が生まれるサービスを提供して、人が行動を起こしていくようなきっかけ、トリガー的な立ち位置を担いクリエイティブを通じて世界をもっとおもしろくするのが私たちのミッションです。
          </div>
        </AboutH2>
      </AboutDesc>
      <BgLogo><Img src={ Logo } alt="logo" /></BgLogo>
    </AboutWrapper>
  )
}

export default About

const AboutWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  padding: 80px 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    padding: 150px 0 0;
  }
`
const AboutDesc = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 16px;
`
const AboutH2 = styled.h2`
  max-width: 950px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 67px;
  letter-spacing: 1.5px;
  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 65px;
  }
  @media screen and (max-width: 600px) {
    font-size: 19px;
    line-height: 52px;
  }
`
const BgLogo = styled.div`
  position: absolute;
  top: 30%;
  right: -150px;
  width: 465px;
  height: 470px;
  z-index: -1;
  @media screen and (max-width: 992px) {
    width: 445px;
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    width: 415px;
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    top: 40%;
    width: 355px;
    height: 340px;
  }
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.8;
`