import React from 'react'
import styled from "styled-components"
import Ryu from "../assets/images/ryu.png"
import Avatar from "../assets/images/avatar.png"
import Note from "../assets/images/note.svg"
import { useInView } from 'react-intersection-observer'
import { InView } from './styles/InView'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

const Member = () => {
  const { ref, inView } = useInView({
    rootMargin: '300px', 
    threshold: 0.2,
  });

  return (
    <>
    <InView />
    <MemberWrapper id="member">
      <MemberH1>Member</MemberH1>
      <OurMember>
        <AboutMember ref={ref}>
          <MemberImg className={inView ? "member-img member-img-slide" : "member-img"}>
            <Img src={ Ryu } alt="Img" />
          </MemberImg>
          <MemberProf className={inView ? "member-txt member-txt-slide" : "member-txt"}>
            <MemberH2>
              豊里竜次
              <Icons>
                <a href="https://mobile.twitter.com/ryu_cignals" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} style={{ fontSize: '22px', color: '#1D9CF0', hight: '100%' }} />
                </a>
                <a href="https://note.com/ryu_cignals/" target="_blank" rel="noopener noreferrer">
                  <NoteLogo>
                    <Logo src={ Note } alt="note" />
                  </NoteLogo>
                </a>
              </Icons>
            </MemberH2>
            <MemberSpan>Cignals 代表 | iNnos Studio運営 | Designer</MemberSpan>
            <MemberDetail>
              沖縄市コザ⇆札幌の２拠点生活。｜ クリエイティブで事業を広く伝えるのが仕事。｜常に好奇心と挑戦心を持ちながら、様々なプロジェクトにクリエイティブなインスピレーションを与えている。熱心にプロジェクトに向き合う情熱的な人々とのコラボレーションや、ユニークな事業をサポートすることが好きです。
            </MemberDetail>
          </MemberProf>
        </AboutMember>
      </OurMember>

      <OurMember>
        <AboutMember >
          <MemberImg className={inView ? "member-img member-img-slide" : "member-img"}>
            <Img src={ Avatar } alt="Img" />
          </MemberImg>
          <MemberProf className={inView ? "member-txt member-txt-slide" : "member-txt"}>
            <MemberH2>久場 良太 <span style={{ fontSize: '16px', color: '#444', fontWeight: 'normal' }}>（Co-creator）</span></MemberH2>
            <MemberSpan>Programmer</MemberSpan>
            <MemberDetail>
              新型コロナウイルスをきっかけに、全くの未経験の状態からフリーランスのプログラマーになることを決意。沖縄市コザの店舗紹介サイト「ゴーゴーコザ」の企画・運営を行いながら、複数のサービス立ち上げにプログラマーとして参画。
            </MemberDetail>
          </MemberProf>
        </AboutMember>
      </OurMember>

      <OurMember>
        <AboutMember>
          <MemberProf style={{ margin: '100px auto 0', width: '100%', padding: '32px 16px', background: '#fff' }}>
            <MemberH2>Cignals メンバー募集</MemberH2>
            <MemberDetail>
              Cignalsにはコミュニティードリブンというマインドがあり、通常の雇用形態だけではなく、協業パートナー 制度を取り入れています。是非あなたのご経験やスキルをCignalsのプロジェクトで活かしてください。業務に対するコミットメントがない上に、Cignalsのネットワークや知見を自由に使うことができ、Cignalsに入ってくるプロジェクトに自由に手を上げることができます。それぞれのキャリアやライフステージに合わせた働き方ができます。
            </MemberDetail>
          </MemberProf>
        </AboutMember>
      </OurMember>
    </MemberWrapper>
    </>
  )
}

export default Member

const MemberWrapper = styled.div`
  max-width: 1200px;
  padding: 120px 16px 0;
  margin: 0 auto;
`
const MemberH1 = styled.h1`
  font-size: 42px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem auto 3.5rem;
  text-align: center;
  @media screen and (max-width: 992px) {
    font-size: 32px;
    margin: 2rem auto 3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 29px;
  }
  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`
const OurMember = styled.div`
  overflow: hidden;
  margin-bottom: 4rem;
`
const AboutMember = styled.div`
  margin: 2rem auto 3rem;
  display: flex;
  justify-content: space-between;
  &:last-child {
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const MemberImg = styled.div`
  width: 450px;
  height: 300px;
  @media screen and (max-width: 768px) {
    width: 450px;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 600px) {
    height: 250px;
  }
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`
const Icons = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 2.5rem;
  gap: 1rem;
`
const NoteLogo = styled.div`
  width: 40px;
  height: 40px;
  display: inline-block;
  padding-top: 6px;
  @media screen and (max-width: 992px) {
    padding-top: 2px;
  }
`
const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const MemberProf = styled.div`
  width: 54%;
  margin: 16px 20px;
  @media screen and (max-width: 992px) {
    width: 51%;
    margin: 5px 20px;
  }
  @media screen and (max-width: 768px) {
    width: 450px;
    margin: 0 0 1.5rem;
  }
`
const MemberH2 = styled.h2`
  font-size: 26px;
  letter-spacing: 1px;
  margin-bottom: 1rem; 
  @media screen and (max-width: 992px) {
    font-size: 22px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`
const MemberSpan = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 0.8rem; 
`
const MemberDetail = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 29px;
  @media screen and (max-width: 992px) {
    font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 15px;
  }
`
