import { createGlobalStyle } from "styled-components";

export const TestAnimation = createGlobalStyle`

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(1500px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@media screen and (max-width: 768px) {
    @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(900px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
    }
}
@media screen and (max-width: 600px) {
    @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(800px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
    }
}

@keyframes showTopText {
    0% { 
        transform: translate3d(0, 100%, 0); 
    } 40%, 
    60% { 
        transform: translate3d(0, 45%, 0); 
    }
    100% { 
        transform: translate3d(0, 0, 0); 
    }
}
@media screen and (max-width: 600px) {
    @keyframes showTopText {
        0% { 
            transform: translate3d(0, 100%, 0); 
        } 40%, 
        60% { 
            transform: translate3d(0, 30%, 0); 
        }
        100% { 
            transform: translate3d(0, 0, 0); 
        }
    }
}
@keyframes showBottomText {
    0% { 
        transform: translate3d(0, -140%, 0); 
    }
    100% { 
        transform: translate3d(0, 0, 0); 
    }
}
@media screen and (max-width: 600px) {
    @keyframes showBottomText {
    0% { 
        transform: translate3d(0, -120%, 0); 
    }
    100% { 
        transform: translate3d(0, 0, 0); 
    }
}
}

.animated-title {
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 1000px;
    overflow: hidden;
}
@media screen and (max-width: 992px) {
    .animated-title {
        width: 700px;
    }
}
@media screen and (max-width: 768px) {
    .animated-title {
        width: 612px;
        top: 50%;
    }
}
@media screen and (max-width: 600px) {
    .animated-title {
        width: 350px;
        top: 50%;
    }
}

.animated-title > div {
    width: 923px;
    left: 37px;
    height: 50%;
    position: absolute;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .animated-title > div {
        width: 657px;
        left: 22px;
        height: 50%;
    }
}
@media screen and (max-width: 768px) {
    .animated-title > div {
        width: 568px;
    }
}
@media screen and (max-width: 600px) {
    .animated-title > div {
        width: 350px;
        left: 0;
    }
}

.animated-title > div div {
    position: absolute;
    bottom: 0;
}
.animated-title > div.text-top {
    top: -14px;
    z-index: 1;
    left: 93px;
}
@media screen and (max-width: 992px) {
    .animated-title > div.text-top {
        top: -10;
        left: 64px;
    }
}
@media screen and (max-width: 768px) {
    .animated-title > div.text-top {
        left: 57px;
    }
}
@media screen and (max-width: 600px) {
    .animated-title > div.text-top {
        left: 0;
    }
}

.animated-title > div.text-top div {
    animation: showTopText 1s ease;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    bottom: -30px;
    transform: translate(0, 100%);
}
@media screen and (max-width: 992px) {
    .animated-title > div.text-top div {
        bottom: -20px;
    }
}
@media screen and (max-width: 600px) {
    .animated-title > div.text-top div {
        bottom: -14px;
    }
}

.animated-title > div.text-bottom {
    bottom: 5px;
}
@media screen and (max-width: 992px) {
    .animated-title > div.text-bottom {
        bottom: 7px;
    }
}
@media screen and (max-width: 768px) {
    .animated-title > div.text-bottom {
        bottom: 10px;
    }
}
@media screen and (max-width: 600px) {
    .animated-title > div.text-bottom {
        bottom: 9px;
    }
}
.animated-title > div.text-bottom div {
    animation: showBottomText 0.6s ease;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    top: -5px;
    transform: translate(0, -100%);
}
`