import { createGlobalStyle } from "styled-components";

export const InView= createGlobalStyle`
  .about-header {
    opacity: 0;
    transform: translateX(-70px);
    transition: 1.2s;
  }
  .about-header-slide {
    opacity: 1;
    transform: translateX(0);
    transition: 1.2s;
  }
  span {
    color: #FF0F3D;
    margin-right: 3px;
  }
  .about-txt {
    opacity: 0;
    transform: translateX(100px);
    transition: 1.2s;
  }
  .about-txt-slide {
    opacity: 1;
    transform: translateX(0);
    transition: 1.2s;
  }


  .project-txt {
    opacity: 0;
    transform: scale(80%);
    transition: 1.2s;
  }
  .project-txt-slide {
    opacity: 1;
    transform: scale(100%);
    transition: 1.2s;
  }
  .project-img {
    opacity: 1;
  }
  .project-img-slide {
    opacity: 1;
  }
  @media screen and (max-width: 600px) {
    .project-txt {
      opacity: 0;
      transform: translateX(-100px);
      transition: 1.2s;
    }
    .project-txt-slide {
      opacity: 1;
      transform: translateX(0);
      transition: 1.2s;
    }
    .project-img {
      opacity: 0;
      transform: translateX(100px);
      transition: 1.2s;
    }
    .project-img-slide {
      opacity: 1;
      transform: translateX(0);
      transition: 1.2s;
    }
  }


  .member-txt {
    opacity: 0;
    transform: scale(80%);
    transition: 1.2s;
  }
  .member-txt-slide {
    opacity: 1;
    transform: scale(100%);
    transition: 1.2s;
  }
  .member-img {
    opacity: 1;
  }
  .member-img-slide {
    opacity: 1;
  }
  @media screen and (max-width: 768px) {
    .member-txt {
      opacity: 0;
      transform: translateX(100px);
      transition: 1.2s;
    }
    .member-txt-slide {
      opacity: 1;
      transform: translateX(0);
      transition: 1.2s;
    }
    .member-img {
      opacity: 0;
      transform: translateX(-100px);
      transition: 1.2s;
    }
    .member-img-slide {
      opacity: 1;
      transform: translateX(0);
      transition: 1.2s;
    }
  }
`