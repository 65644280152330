import React from 'react'
import styled from "styled-components"
import Video from '../assets/videos/Video.mp4'
import { TestAnimation } from './styles/TextAnimation'

const Hero = () => {
return (
<HeroWrapper id="hero">
    <TestAnimation />
    <HeroBg>
        <VideoBg src={ Video } type="video/mp4" autoPlay loop muted playsInline />
    </HeroBg>

    <HeroContent>
    <div className='animated-title'>
        <div className='text-top'>
            <div>
                <HeroHeader>
                    <HeroH1>
                    Hi there, We are <HeroSpan>Cignals</HeroSpan>.<br/>
                    Creative Community.<br/>
                    </HeroH1>
                </HeroHeader>
                <SubHeader>
                    <HeroH1>
                    Hi there, <br/>
                    We are <HeroSpan>Cignals</HeroSpan>.<br/>
                    Creative Community.<br/>
                    </HeroH1>
                </SubHeader>
            </div>
        </div>
        <Border></Border>
        <div className='text-bottom'>
            <div>
                <HeroHeader>
                    <HeroH1>
                    BASED IN OKINAWA JAPAN.
                    </HeroH1>
                </HeroHeader>
                <SubHeader>
                    <HeroH1>
                    BASED IN <br/>
                    OKINAWA JAPAN.
                    </HeroH1>
                </SubHeader>
            </div>
        </div>
    </div>
    </HeroContent>
</HeroWrapper>
)
}

export default Hero

const HeroWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 80px 16px 0;
    position: relative;
    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: rgba(255,255,255, 0.65);
    }
    @media screen and (max-width: 768px) {
        align-items: flex-start;
        padding: 130px 16px 0;
    }
`
const HeroBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`
const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`
const HeroContent = styled.div`
    z-index: 3;
`
const Border = styled.div`
    width: 100%;
    border-bottom: 1px solid red;
    position: absolute;
    top: -15px;
    animation: fadeIn 0.6s ease-in forwards;
`
const HeroHeader = styled.section`
    width: 100%;
    text-align: center;
    @media screen and (max-width: 600px) {
        display: none;
    }
`
const SubHeader = styled.section`
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
`
const HeroH1 = styled.h1`
    font-size: 60px;
    line-height: 105px;
    letter-spacing: 1.5px;
    margin-top: 0;
    @media screen and (max-width: 992px) {
        font-size: 42px;
        line-height: 105px;
    }
    @media screen and (max-width: 768px) {
        font-size: 36px;
        line-height: 95px;
    }
    @media screen and (max-width: 600px) {
        font-size: 28px;
        line-height: 60px;
    }
`
const HeroSpan = styled.span`
    color: #FF0F3D;
`

