import React, { useEffect } from 'react'
import styled from "styled-components"
import ServiceOne from "../assets/images/creativespace.jpg"
import ServiceTwo from "../assets/images/download-7879.jpg"
import ServiceThree from "../assets/images/creatoreconomy.jpg"
import Aos from 'aos'
import "aos/dist/aos.css"

const Service = () => {
  useEffect(() => {
    Aos.init({
      offset: 300,
      duration: 700,
    })
  }, [])

  return (
    <ServiceWrapper id="service">
      <ServiceH1>Service</ServiceH1>
      <OurService>
        <AboutService data-aos="fade-up">
          <ServiceImg src={ ServiceOne } alt="logo" />
          <ServiceH2>クリエイティブスタジオ事業</ServiceH2>
          <ServiceDetail>
            Web制作・運用 / グラフィックデザイン制作（ロゴ・ポスター・名刺など） / 写真映像撮影・編集 / PR / EC構築 /など。
          </ServiceDetail>
        </AboutService>
        <AboutService data-aos="fade-up" data-aos-delay="250">
          <ServiceImg src={ ServiceTwo } alt="logo" />
          <ServiceH2>クリエイター支援事業</ServiceH2>
          <ServiceDetail>
            アーティスト・クリエイターを活用した事業の企画・プロディースを行います。
          </ServiceDetail>
        </AboutService>
        {/* <AboutService data-aos="fade-up" data-aos-delay="500">
          <ServiceImg src={ ServiceThree } alt="logo" />
          <ServiceH2>クリエイターエコノミー</ServiceH2>
          <ServiceDetail>
            沖縄からクリエイターを中心としたエコノミーを創出。クリエイターの収益化を支援し、 創作活動の基盤作りに貢献いたします。
          </ServiceDetail>
        </AboutService> */}
      </OurService>
    </ServiceWrapper>
  )
}

export default Service

const ServiceWrapper = styled.div`
  max-width: 992px;
  padding: 120px 16px 0;
  margin: 0 auto;
`
const ServiceH1 = styled.h1`
  font-size: 42px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem auto 1.5rem;
  text-align: center;
  @media screen and (max-width: 992px) {
    font-size: 32px;
  }
  @media screen and (max-width: 768px) {
    font-size: 29px;
  }
  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`
const OurService = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const AboutService = styled.div`
  max-width: 350px;
  margin: 2rem auto;
  @media screen and (max-width: 992px) {
    max-width: 400px;
    margin: 2rem auto 1rem;
  }
  @media screen and (max-width: 600px) {
    max-width: 450px;
  }
`
const ServiceImg = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
`
const ServiceH2 = styled.h2`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin: 16px 0;
  letter-spacing: 1px;
  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
`
const ServiceDetail = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 29px;
  @media screen and (max-width: 992px) {
    font-size: 15px;
  }
`