import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import slugify from 'slugify'
import { useInView } from 'react-intersection-observer'
import { InView } from './styles/InView'

const BlogHome = ({ blogs = [] }) => {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  return (
    <BlogWrapper id="news">
      <InView />
      <BlogH1 ref={ref}>News</BlogH1>

      { blogs.map(blog => {
        const {id,title,mainTitle,createdAt} = blog
        const slug = slugify(title, { lower:true })
        
        return (
          <Blog key={id} to={`/${slug}`} className={inView ? "news news-zoom" : "news"}>
            <BlogContent>
              <BlogDate>{ createdAt }</BlogDate>
              <BlogH2>{ mainTitle }</BlogH2>
            </BlogContent>
          </Blog>
        )
      }) }

      <Button className={inView ? "news-btn news-btn-slide" : "news-btn"}>
        <BlogLink to="/news">すべて見る</BlogLink>
      </Button>
    </BlogWrapper>
  )
}

export default BlogHome

const BlogWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 120px 16px 50px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  letter-spacing: 1px;
`
const BlogH1 = styled.h1`
  font-size: 42px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 2rem auto 1.5rem;
  text-align: center;
  @media screen and (max-width: 992px) {
    font-size: 32px;
    margin: 2rem auto 3rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 29px;
  }
  @media screen and (max-width: 600px) {
    font-size: 26px;
  }
`
const Blog = styled(Link)`
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  text-decoration: none;
  color: #191919;
  padding: 20px 0;
  transition: all 0.3s;
  &:hover {
    opacity: 0.6;
  }
`
const BlogContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const BlogDate = styled.label`
  display: block;
  width: 30%;
  font-size: 14px;
  padding-top: 2px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 14px;
  }
`
const BlogH2 = styled.h2`
  font-family: 'Noto Sans JP', sans-serif;
  width: 70%;
  font-size: 20px;
  line-height: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; 
  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    font-size: 16px;
  }
`
const Button = styled.div`
  margin: 3rem auto;
`
const BlogLink = styled(Link)`
  font-family: 'Noto Sans JP', sans-serif;
  display: inline-block;
  width: 240px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 1.5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background: #373737;
  border-radius: 6px;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 600px) {
    height: 50px;
    line-height: 50px;
  }
`